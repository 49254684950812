<template>
  <MDBCard
    class="checking-card bg-transparent rounded-1 overflow-hidden shadow-0"
    text="center"
  >
    <MDBCardBody class="rounded-1 p-0">
      <!-- Address -->
      <MDBCardText class="w-100 bg-box p-3 my-0">
        <div class="fw-bold mb-1">
          <span class="fs-5">
            {{ props.checking.Accomodation.Address.Address1 }}
          </span>
          <br />
          {{
            props.checking.Accomodation.Address.ZipCode +
            " " +
            props.checking.Accomodation.Address.City
          }}
        </div>
      </MDBCardText>

      <div class="card-separator"></div>

      <!-- Details -->
      <MDBCardText class="w-100 bg-box p-3 mb-0">
        <MDBContainer fluid class="p-0">
          <MDBRow>
            <MDBCol>
              <div class="text-start text-muted w-100">
                Type
                {{ props.checking.Type }}
              </div>
              <div class="text-start text-muted w-100">
                Surface :
                {{ props.checking.Accomodation.Surface }}
                m²
              </div>
            </MDBCol>
            <MDBCol class="d-flex justify-content-end">
              <MDBBadge
                v-if="props.checking.Occupants.length === 0"
                color="secondary"
                tag="div"
                class="h-fit"
              >
                <div class="fst-italic fw-normal w-fit fs-6">
                  {{ texts.occupant }}
                </div>
              </MDBBadge>
              <div
                v-else
                class="fst-italic fw-normal text-muted w-fit fs-6 ms-auto"
              >
                {{ texts.occupant }}
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBCardText>

      <div class="card-separator"></div>

      <!-- Appointment -->
      <MDBCardText class="bg-box p-3 mb-0">
        <CheckingSumAppointment :appointment="appointment" :style="'col'" />
      </MDBCardText>
    </MDBCardBody>
  </MDBCard>
</template>

<script setup>
import {
  MDBBadge,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCol,
  MDBRow,
  MDBContainer,
} from "mdb-vue-ui-kit";

import CheckingSumAppointment from "./CheckingSumAppointment";

import { defineProps } from "vue";

const props = defineProps({
  checking: Object,
});

import Setup from "./Setup";

const { appointment, texts } = Setup(props.checking);
</script>

<style lang="scss">
.checking-card {
  width: 300px;
}
</style>

<style lang="scss" scoped>
.btn {
  bottom: -0.5rem;
  right: -0.25rem;
}
</style>